jQuery(document).ready(function($){

    adjustBreadCrumbPadding();

    $(window).resize(function(){
        adjustBreadCrumbPadding();
    });


    function adjustBreadCrumbPadding(){

        if ($('.breadcrumbs').length > 0){
            var header_height = $('.header').outerHeight();
            var padding = header_height + 20;
            $('.breadcrumbs').css('padding-top', padding);
        }


    }


    adjustMarginBanner();

    $(window).resize(function(){
        adjustMarginBanner();
    })

    function adjustMarginBanner(){

        var header_height = $('.header').height();

        if ($('.large-banner').hasClass('home-banner')){
            return;
        }


        else{
            $('.banner-text').css('margin-top', (header_height / 2 ) + 'px');
        }
    }


    $(window).scroll(function(){
        adjustHeaderStyling();
    });


    function adjustHeaderStyling(){

        if ($('.header').hasClass('green')){

            var $height = $(window).scrollTop();

            if($height > 0) {
                $('.header').addClass('white');
                } 
            else{
    
                $('.header').removeClass('white');
    
            }
        }
    }


    if ($('.industries').length > 0 && (!$('.industries').hasClass('text-given'))){
        industriesClick($('.industry-col').first());
    }

    $('.industry-col').click(function(){

        if (window.matchMedia("(min-width: 992px)").matches){
            industriesClick($(this));
        }
    });

    function industriesClick($element){

        $('.industry-col').removeClass('active');
        $element.addClass('active');

        var title = $element.find('h3 .industry-title').html();
        var text  = $element.find('.hidden-industry-copy').html();
        var link = $element.find('.industry-link').attr('href');
        var link_text = $element.find('.industry-link .link-text').text();

        $('.chosen-industry h3').text(title);
        $('.chosen-industry p').text(text);
        $('.chosen-industry a ').attr('href', link)
        $('.chosen-industry a ').text(link_text);
        console.log('the link text is' + link_text);
        if (link_text !== ""){
            $('chosen-industry a').show();
        }
        else {
            $('chosen-industry a').hide();

        }


    }


    //projects slider

    $('.our-projects .arrow').click(function(){

        var direction;
        
        if ($(this).hasClass('arrow-next')){
            direction = "forward";

        }

        else{
            direction = "backword";
        }

        var image_length = $('.our-project-imgs').length;
        var image_container_markup = []

        $('.our-project-imgs').each(function(index, value){

            image_container_markup.push($(this).closest('.our-project-img-container').html());


        });




        $('.our-project-img-container').each(function(index, value){

            var next_image_markup;
            //end of the line, use first image

            if (direction === "forward"){

                if (image_length == (index  + 1)){
                    next_image_markup = 0;
                }
    
                else{
    
                    next_image_markup = index + 1;
                }
                
            }

            else{

                if (index == 0){
                    next_image_markup = image_length - 1;
                }

                else{
                    next_image_markup = index - 1;
                }
            }
            
            // console.log(image_container_markup[next_image_markup]);return;
            $(this).fadeOut(0).fadeIn(1000).html(image_container_markup[next_image_markup]);


            // $(this).html(image_container_markup[next_image_markup]);



        });
        

    });


    //gallery slider

    $('.three-col-text-image .arrow').click(function(){
        var direction;
        
        if ($(this).hasClass('arrow-next')){
            direction = "forward";

        }

        else{
            direction = "backword";
        }

        console.log(direction);

        var image_length = $('.gallery-imgs').length;
        console.log(image_length);
        var image_container_markup = []

        $('.gallery-imgs').each(function(index, value){

            image_container_markup.push($(this).closest('.gallery-image-container').html());


        });

        console.log(image_container_markup);




        $('.gallery-image-container').each(function(index, value){

            var next_image_markup;
            //end of the line, use first image

            if (direction === "forward"){

                if (image_length == (index  + 1)){
                    next_image_markup = 0;
                }
    
                else{
    
                    next_image_markup = index + 1;
                }
                
            }

            else{

                if (index == 0){
                    next_image_markup = image_length - 1;
                }

                else{
                    next_image_markup = index - 1;
                }
            }
            
            // console.log(image_container_markup[next_image_markup]);return;
            $(this).fadeOut(0).fadeIn(1000).html(image_container_markup[next_image_markup]);


            // $(this).html(image_container_markup[next_image_markup]);



        });
       
    })


    $('.three-col-img-vid .vid-img-container').click(function(){

        $('.play-button').hide();
        
        video_src = $(this).data('vid-src');
        

        if (video_src == 'embed'){

            $(this).addClass('video-playing')
                        
            $(this).html('');

            $(this).html(text_with_video_html);
            

        }

        else{
            $(this).addClass('video-playing')
            $(this).find('img').hide();
            var video_element =  $(this).find('video');
            video_element.show();
            video_element[0].play();

        }
    });


    //testimonials

    $('.testimonials .arrow').click(function(){
        
        var direction;
        
        if ($(this).hasClass('arrow-next')){
            direction = "forward";

        }

        else{
            direction = "backword";
        }


        var testimonials = $('.testimonials .testimonial');

        

        var active_testimonial = $('.testimonials').find('.testimonial.active');

        var active_testimonial_number = active_testimonial.data('testimonial-number');

        active_testimonial.hide();
        active_testimonial.removeClass('active')

        if (direction == 'forward'){

            if (testimonials.length === active_testimonial_number ){
                $('.testimonial').first().fadeIn(2000).addClass('active');
            }
            else{
                active_testimonial.next().fadeIn(2000).addClass('active');
            }
        }

        else{

            if (active_testimonial_number === 1){
                $('.testimonial').last().fadeIn(2000).addClass('active');
            }
            else{
                active_testimonial.prev().fadeIn(2000).addClass('active');

            }
        }

    });



    
    $('.blog-item').matchHeight();

    $('.related-resources .related-blog').matchHeight();



    // $('.image-video-container').matchHeight();
    $(document).on('click', '.portfolio-item.video', function(index, value){
        
        var video_source = $(this).data('video-type');

        if (video_source == 'embed'){

            var random_number = $(this).data('random-number');


            $(this).find('.image-video-container').html('');
            var video_html = video_object[random_number];

            $(this).find('.image-video-container').html(video_html);

        }

        else{
            $(this).find('.overlay').css('opacity', '0');
            $(this).find('img').css('opacity', '0');
            var video_element =  $(this).find('video');
            video_element.show();
            video_element[0].play();
        }

       
        
    });

    if ($('.three-col-text-image').length > 0 && $('.three-col-text-image .gallery-container img').length > 5){
        
        $('.three-col-text-image .gallery-container')
    }


});