jQuery(document).ready(function($){


    //user clicks on an image in the portfolio gallery
    $(document).on('click', '.portfolio-item', function(){


        $('.slider-container .slides').html('');
        
        if ($(this).hasClass('image')){

            var image_src = $(this).find('.modal-image-container').html();
            var additional_information = $(this).find('.additional-information').html();
            
            $('#portfolio-modal .additional-info').html(additional_information);

            var slider_images = $(this).data('slider-images');
            slider_images = slider_images.split(',');


            var first_image = $(this).find('img').attr('src');


            $('.slides').append('<div class="image-holder first"><img src="'+first_image+'"/></div>');

            if (slider_images.length > 1){
                $(slider_images).each(function(index, value){
                    $('.slides').append(
                        '<div class="image-holder"><img src="'+value+'"/></div>'
                    )
                });
            }


            console.log(image_src);

            $('#portfolio-modal .img-vid-container').html('');

            $('#portfolio-modal .img-vid-container').html(image_src);

        }

    });

    $(document).keydown(function(e) {

        if ($('#portfolio-modal').hasClass('show')){
            var code = (e.keyCode ? e.keyCode : e.which);
            var direction;
            
            if (code == 39){
                direction = 'forward';
            }
            else if (code == 37){
                direction = "backword";
            }
    
            controlSlider(direction);
        }
       
       
    });

    //gallery modal slider controls

    $(document).on('click', '#portfolio-modal .slider-container .arrow, #portfolio-modal .modal-body-inner .arrow', function(index, value){
        var direction;
        
        if ($(this).hasClass('arrow-next')){
            direction = "forward";

        }

        else{
            direction = "backword";
        }

        controlSlider(direction);

        

    });



    function controlSlider(direction){
        var image_length = $('.slides .image-holder').length;
        var image_container_markup = []

        $('.slides img').each(function(index, value){

            image_container_markup.push($(this).closest('.image-holder').html());


        });




        $('.slides .image-holder').each(function(index, value){

            var next_image_markup;
            //end of the line, use first image

            if (direction === "forward"){

                if (image_length == (index  + 1)){
                    next_image_markup = 0;
                }
    
                else{
    
                    next_image_markup = index + 1;
                }
                
            }

            else{

                if (index == 0){
                    next_image_markup = image_length - 1;
                }

                else{
                    next_image_markup = index - 1;
                }
            }
            
            // console.log(image_container_markup[next_image_markup]);return;

            $(this).stop(true, true);
            if ($(this).is(':visible')){
                $(this).fadeOut(0).fadeIn(1000)
            }
            $(this).html(image_container_markup[next_image_markup]);

            var first_image_src = $('.slides .image-holder.first img').attr('src');

            $('#portfolio-modal .modal-image-holder img').attr('src', first_image_src);


            // $(this).html(image_container_markup[next_image_markup]);

        });

        $('#portfolio-modal .modal-image-holder img').fadeOut(0).fadeIn(1000);
    }


    //replace the featured image in the gallery modal
    $(document).on('click', '#portfolio-modal .slides .image-holder', function(index, value){
        var image_src = $(this).find('img').attr('src');

        $('#portfolio-modal .modal-image-holder img').attr('src', image_src);

    });


    
});