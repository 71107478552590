jQuery(document).ready(function($){

    adjustHeaderStyling();

    $(window).scroll(function(){
        adjustHeaderStyling();
    });


    function adjustHeaderStyling(){

        if ($('.header').hasClass('green')){

            var $height = $(window).scrollTop();

            if($height > 0) {
                $('.header').addClass('white');
                } 
            else{
    
                $('.header').removeClass('white');
    
            }
        }
    }

    $('.mobile-menu-button').click(function(){
        $(this).toggleClass('is-active');
        $('.mobile-menu-container').toggleClass('mobile-menu-show');

        if ($('.mobile-menu-container').hasClass('mobile-menu-show')){
            // $('body').css('overflow-y', 'hidden');
         }
         else{
            // $('body').css('overflow-y', 'scroll');

         }
    });

    $('.large-banner .scroll-outer').click(function(){
        $(this).addClass('clicked');

        let next_section = $(this).closest('section').next('section');
        let header_height = $('.header').height();

            $('html,body').animate({
                scrollTop: $(next_section).offset().top - header_height},
                'slow');

        setTimeout(function(){ $('.scroll').removeClass('clicked') }, 1000);

    });

    $(window).resize(function(){
        if (window.matchMedia("(min-width: 992px)").matches){
            $('.mobile-menu-container').removeClass('mobile-menu-show');
            $('.mobile-menu-button').removeClass('is-active');
        }
    });

});